.return-photo {
    width: 100vw;
    object-fit: contain;
    /*max-height: 100vh;*/
    overflow: hidden;
}

.return-photo-cntr {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: rgba(34, 34, 34, 0.9)
}
