.toptab-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.toptab-inner {
    background: rgba(118, 118, 128, 0.12);
    display: flex;
    flex-direction: row;
    height: 44px;
    border-radius: 8px;
    /*justify-content: center;*/
    align-items: center;
    padding: 2px;
    z-index: 100;
}

.toptab {
    height: 40px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.07800000160932541px;
    text-align: center;
    color: black;
    border: none;
    background: transparent;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}
