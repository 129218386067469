.container {
    padding-top: 70px;
}

.titleContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.date {
    width: 100px;
    border: none;
    align-self: center;
}

.dateRow {
    border: none;
    padding: 7px 20px;
    height: 48px;
}
