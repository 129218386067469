.scannerTabContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100vw;
    flex: 1;
    height: calc(100vh - var(--navbar-height) - 70px) !important;
    background: var(--grey22);
}

.scannerCntInfo {
    text-align: center;
    height: 20px;
    padding: 0px 6px;
    margin-bottom: 68px;
    margin-top: 30px;
    background: rgba(118, 118, 128, 0.24);
    border-radius: 7px;
}

.scannerInfo {
    margin-top: 30px;
}

.scannerInfoSubtitle {
    margin-bottom: auto;
    justify-content: center;
}
