.itemContainer {
    width: calc(100vw - 40px);
    margin: 20px 20px 10px 20px;
}

.container {
    position: fixed;
    bottom: var(--tab-height);
    margin-bottom: 10px;
    left: 0px;
    right: 0px;
    z-index: 1000;
}
