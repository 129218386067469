:root {
  --primary: #6e6eff;
  --hover: #A0A0FF;
  --btnBlue: #5A5AFF;
 --black02: rgba(0,0,0, 0.02);
 --black25: rgba(0, 0, 0, 0.25);
 --black45: rgba(0, 0, 0, 0.45);
  --black80: rgba(0, 0, 0, 0.8);
 --black85: rgba(0, 0, 0, 0.85);
  --greydc: #DCDCDC;
  --greyd9: #D9D9D9;
  --greyc8: #c8c8c8;
  --greyc6: #C6C6C8;
  --greyb4: #B4B4B4;
  --grey92: #929292;
  --grey93: #939393;
  --grey96: #969696;
  --grey8c: #8c8c8c;
  --grey64: #646464;
  --grey3c: #3c3c3c;
  --grey3a: #3a3a3a;
  --grey22: #222222;
  --grey26: #262626;
  --whitefa: #fafafa;
  --whitef5: #f5f5f5;
  --whitef3: #f3f3f3;
  --whitef9: #f9f9f9;
  --white45: rgba(255, 255, 255, 0.45);
  --white85: rgba(255, 255, 255, 0.85);
  --dark: #141E28;
  --error: #FF9F90;
  --errorActive: #FF7864;
  --badge: #FF4D4F;
  --yellow: #FFC61A;
  --pink: #FF007A;
  --vh: 1vh;
  --tab-height: 83px;
  --navbar-height: 44px;
  --scanner-tab-height: 28px;
  --btn-primary-height: 60px;
}

/*Typography*/
.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitle {
  font-size: 18px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.subtitle2 {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.body1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey26);
}

.body2 {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.body {
  color: var(--black85);
  font-size: 14px !important;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.body1 {
  color: var(--black85);
  font-size: 13px !important;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.small {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.small1 {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
}

.black {
  font-weight: 900;
}

.bold {
  font-weight: 700 !important;
}

.medium {
  font-weight: 500;
}

.grey92 {
  color: var(--grey92);
}

.grey8c {
  color: var(--grey8c);
}

.map-class {
  height: calc(var(--vh, 1vh) * 100) !important;
}

.grey93 {
  color: var(--grey93);
}

.grey96 {
  color: var(--grey96) !important;
}

.info {
  color: var(--primary);
}

.primary {
  color: var(--primary)!important;
}

.primary-bg {
  background: var(--primary)!important;
}

.primaryActive {
  color: var(--primary);
}

.white {
  color: white !important;
}

.white45 {
  color: var(--white45);
}

.white85 {
  color: var(--white85);
}

.grey22 {
  color: var(--grey22);
}

.grey64 {
  color: var(--grey64);
}

.black45 {
  color: var(--black45) !important;
}

.black85 {
  color: var(--black85) !important;
}

.black85-bg {
  background: var(--black85) !important;
}

.black45-bg {
  background: var(--black45) !important;
}

.black25-bg {
  background: var(--black25) !important;
}

.error {
  color: var(--error) !important;
}

.errorActive {
  color: var(--errorActive) !important;
}

.error-bg {
  background: var(--error) !important;
}

.errorActive-bg {
  background: var(--errorActive) !important;
}

.border-top {
  box-shadow: inset 0px 1px 0px #F0F0F0;
}

.border-right {
  box-shadow: inset -1px 0px 0px #F0F0F0;
}

.border-left {
  box-shadow: inset -1px -1px 1px -1px #F0F0F0;
}

.flex {
  flex: 1
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center
}

.nowrap {
  white-space: nowrap;
  word-wrap: break-word;
}

.preline {
  white-space: pre-line;
}

.text-inside {
  text-overflow: ellipsis;
  overflow: hidden;
}

.round-white {
  background: white;
  border-radius: 14px;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}

/*common styles*/
.container {
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: var(--primary);
}

.inner-container {
  width: calc(100% - 40px);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right;
}

/*
ant design button B styling
 */
.ant-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid var(--btnBlue);
  width: 100%;
  height: 32px;
  border-radius: 7px;
  color: var(--btnBlue);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--hover);
}

.ant-btn[disabled] {
  color: var(--black25);
  background-color: var(--whitef5);
  border: 1px solid var(--greyd9);
}

.ant-btn-primary,
.ant-btn-primary:active {
  height: var(--btn-primary-height);
  border-radius: 14px;
  border-width: 0;
  background: var(--primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: white;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: var(--primary);
  border-color: var(--hover);
}

.ant-btn-primary[disabled] {
  color: var(--black25);
  background: var(--whitef5);
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  border-color: var(--hover);
}

.btn-b,
.btn-b:active {
  background: var(--grey22);
}

.btn-b:hover,
.btn-b:focus  {
  color: #fff;
  background: var(--grey3c);
}

.btn-b[disabled] {
  color: var(--black25);
  background: var(--whitef5);
}

.btn-c {
  background: var(--errorActive);
}

a:hover {
  color: var(--primary);
}

.btn-c:hover,
.btn-c:focus,
.btn-c:active  {
  color: #fff;
  background: var(--error);
}

.btn-c[disabled] {
  color: var(--black25);
  background: var(--whitef5);
}

.ant-btn-text {
  color: var(--primary);
  border-width: 0px;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: var(--primary);
  background-color: transparent;
}

.ant-tag {
  height: 24px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--primary)
}

.ant-tag-magenta {
  height: 24px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  color: var(--primary)
}
/*
ant drawer
 */
.ant-drawer-content {
  background: transparent;
}

.ant-drawer-body {
  padding: 0px;
}

.ant-drawer-header {
  padding: 0px;
  height: 0px;
}

.ant-message {
  position: fixed;
  bottom: 120px !important;
  top: unset !important;
  color: white;
}

.ant-message-notice-content {
  background-color: var(--grey26);
  border-radius: 7px;
}

/*
  input styling
 */

.ant-input {
  height: 60px;
  width: 100%;
  border-radius: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey26);
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused
{
  border-color: var(--hover);
}

.ant-input-affix-wrapper {
  align-items: center;
  height: 60px;
  width: 100%;
  border-radius: 14px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover
{
  border-color: var(--hover);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--hover);
}

.ant-input-affix-wrapper .ant-input{
  height: 60px;
  background-color: transparent;
}

.ant-input:disabled {
  background-color: var(--greydc);
}

.ant-input::placeholder {
  color: var(--grey8c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

/*select*/
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--hover);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--hover);
}

.ant-select-item {
  min-height: 60px;
  color: var(--black85);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  align-items: center;
  text-align: left;
}

.ant-select-dropdown {
  border-radius: 14px;
  top: -200px;
}

.ant-select-selection-placeholder {
  color: var(--grey8c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  width: 100px;
  text-align: left;
}

.ant-select-arrow {
  color: var(--black85);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector
{
  border-radius: 14px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 60px;
  align-items: center;
  width: calc(100vw - 40px);
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 7px;
  height: 36px;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.ant-select-dropdown-sm {
  height: 80px;
  border-radius: 7px;
}

.ant-switch-checked {
  background-color: var(--primary);
}

/*modal custom design*/
.ant-modal-mask {
  background: var(--black02);
  backdrop-filter: blur(0.5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-confirm .ant-modal-body {
  padding: 20px 0px 0px 0px;
  background: rgba(34,34,34, 0.9);
  filter: drop-shadow(-11.0895px 10.2974px 36.437px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(224px);
  border-radius: 21px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 700;
  color: white;
  text-align: center;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0px;
  padding: 12px 20px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 295px;
  border-bottom: 1px solid var(--black45);
}

.ant-modal-confirm-body > .anticon {
  transform: scale(0);
  width: 0px;
  padding: 0px;
  margin: 0px
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0px;
}

.ant-modal-centered .ant-modal {
  max-width: 295px;
}

.ant-modal-content {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  white-space: pre-line;
  backdrop-filter: blur(48px);
  background: var(--black85);
  max-width: 295px;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 21.3869px;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 30px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-confirm {
  max-width: 295px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  flex: 1;
  width: 100%;
  margin: 0px;
  color: var(--primary);
}

.ant-modal-confirm-btns .ant-btn{
  height: 50px;
  border: none;
  background: none;
  margin: 0px;
  color: var(--primary)
}

.ant-modal-confirm .ant-modal-confirm-btns {
  width: 100%;
  margin: 0px;
  display: flex;
  color: var(--primary);
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn  {
  color: white;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  flex: 1;
}

.ant-modal-footer {
  padding: 0px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 2px 2px;
  margin: 0px;
  border: none;
}

.ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 10px;
  margin: 0px;
  max-width: 295px;
}

/**radio*/
.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary);
}

.ant-radio-inner::after {
  background-color: var(--primary);
}

/*checkbox*/
.ant-checkbox-wrapper {
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 14px;
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--black85)
}

.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}
.ant-checkbox-inner{
  top: -3px;

}

.ant-checkbox-inner:hover{
  border-width: 0px;

}

.ant-checkbox-checked::after {
  border-width: 0px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--primary);
  border: var(--primary);
}

/*collapse*/
.ant-collapse {
  background-color: var(--whitefa);
  padding: 0px 0px;
  border-width: 0px;
  border-radius: 0px;
  border-bottom: 0px;
  box-shadow: none;
}

.ant-collapse-item {
  background-color: var(--whitefa);
  padding: 0px 0px;
  margin: 0px;
  box-shadow: none;
  border-bottom: 0px;
}

.ant-collapse-content {
  border-top: none;
  background-color: var(--whitefa);
}

/* progress*/
.ant-progress {
  border-radius: 10px;
  border: 1px solid #000000;
}

.ant-progress-inner  {
  margin-bottom: 2px;
  padding-left: 1px;
  padding-right: 1px;
}

.bottom-btn {
  width: calc(100% - 40px);
  position: fixed;
  right: 20px;
  left: 20px;
  bottom: 34px;
}

.icon:hover {
  fill: #DA4567;
}

.minus {
  fill: var(--greyd9)
}

.minus:hover {
  fill: var(--greyb4)
}

.camera-inner-circle:hover {
  transform: scale(0.95,0.95);
  align-self: center;
  transition: scale 1s ease;
  transform-origin: center center;
}

/*ant-tooltip*/
.ant-tooltip .ant-tooltip-inner {
  background: #222222;
  border-radius: 14px;
}

.row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.space-between {
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

#fixed {
  position: fixed;
}

#fixed.absolute {
  position: absolute;
}

.popup-title {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.popup-message {
  margin: 5px;
  font-size: 12px;
  text-align: center;
  color: white;
  white-space: pre-line;
  line-height: 20px;
}

.popup-btn.cancel {
  color: white;
}

.popup-btn.ok {
  color: var(--primary);
}

.popup-btn-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.popup-btn-container.border {
  border-top: 1px solid var(--black45);
}

.popup-btn.border {
  border-top: 1px solid var(--black45);
}

.popup-btn {
  text-align: center;
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  height: 44px;
  margin: 0px !important;
  padding: 0;
  border-radius: 0px
}

.popup-btn.height {
  flex: unset!important;
}

.popup-btn-divider {
  background: var(--black45);
  width: 1px;
  height: 44px;
}

.popup-btn.ok:hover {
  color: var(--primary);
}

.popup-error {
  text-align: center;
  color: var(--error);
}

.popup-error:hover {
  color: var(--errorActive)
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
