.local-select {
    margin-top: 30px;
}

.btn-add {
    margin-top: 10px;
}

.local-info-title {
    margin-top: 30px;
    line-height: 30px;
}

.local-info {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: left;
}

.sector-item-container {
    height: 60px;
    background: white;
    border: 1px solid var(--greyd9);
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    margin-bottom: 10px;
}

.sector-item-container:hover {
    border: 1px solid var(--primary);
}

.sector-item-label {
    width: 100%;
}

.sector-item-btn {
    border-width: 0px;
    width: 10%;
    padding-right: 0px;
    padding-bottom: 0px;
}
