.vehicle-container {
    margin-bottom: 100px;
}

.vehicle-select {
    margin-top: 10px;
    /*width: 100%;*/
}

.vehicle-info {
    margin-top: 10px;
    white-space: pre-line;
}
