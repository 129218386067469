.bottomBtn {
    position: fixed;
    left: 0px;
    width: calc(100vw - 40px);
    bottom: 5px;
}

.listContent {
    margin-bottom: var(--btn-primary-height);
    padding-bottom: 20px;
}

.sortContainer {
    margin-left: auto;
    margin-bottom: 30px !important;
    background: red;
}

.option {
    height: 20px;
    fontSize: 12px;
    color: #00000085;
    min-height: 30px
}
