.container {
    width: calc(100vw - 40px);
    margin: 20px;
}

.safetyImg {
    width: 20px;
    height: 20px;
}

.text {
    color: black;
    white-space: pre-wrap;
}

.textError {
    white-space: pre-wrap;
    color: var(--errorActive)
}

.divideItem {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    width: 100%;
    height: 52px;
    border: none;
    border-radius: 14px;
    align-items: center;
    margin-top: 0.5px;
}
