.scannerTabContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100vw;
    flex: 1;
    height: calc(100vh - var(--navbar-height) - 70px) !important;
    background: var(--grey22);
}

.scannerContainer {
    margin-top: var(--navbar-height);
    background: var(--grey22);
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px;
}

.barcodeScreenTopInfo {
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(var(--scanner-tab-height) + 40px);
    left: 0px;
    right: 0px;
    height: auto;
    background: var(--grey22);
}

.scannerTopInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    flex: 1;
}

.scannerBottomInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    flex: 1;
}

.damagePopupContent {
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: center;
    color: white
}

.scannerErrorTitle {
    margin-top: auto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primary)
}

.scannerError {
    line-height: 22px;
    margin: 10px 30px;
    text-align: center;
    height: 22px;
    margin-bottom: auto;
}

.scannerCntInfo {
    text-align: center;
    height: 20px;
    padding: 0px 6px;
    margin-bottom: 68px;
    margin-top: 30px;
    background: rgba(118, 118, 128, 0.24);
    border-radius: 7px;
}

.scannerInfo {
    margin-top: 30px;
}

.scannerInfoSubtitle {
    margin-bottom: auto;
    justify-content: center;
}

.btnDamaged {
    width: 231px;
    height: 36px;
    background: white;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    border-radius: 8.91px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: black !important;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.btnDamaged:hover,
.btnDamaged:active,
.btnDamaged:focus
{
    width: 231px;
    height: 36px;
    background: var(--white85);
    border-radius: 8.91px;
    font-size: 12px;
}

.qrContainer {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    margin-top: auto;
    margin-bottom: auto;
}
