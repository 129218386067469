.delivery-detail-container {
    padding-top: calc(var(--navbar-height) + 20px);
}

.shipping-warning-img {
    margin-bottom: 5px;
}

.delivery-detail-inner {
    background: white;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
}

.delivery-detail-row {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    min-height: 60px;
    align-items: center;
    padding: 9px 10px;
}

.delivery-detail-info {
    line-height: 20px;
    margin-top: 5px;
}

.delivery-detail-btn-edit {
    width: auto;
    flex: 0;
    font-weight: 700;
    font-size: 12px;
    padding: 0px  0px 0px 10px;
}

.delivery-detail-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.delivery-icon {
    width: 43px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    box-shadow: none;
}

.delivery-button-container {
    margin-top: auto;
    padding-top: 20px;
    display: flex;
    margin-bottom: 20px;
}

.delivery-button-complete {
    flex: 3;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.delivery-button-cancel {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.delivery-method-radio {
    color: var(--black85) !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

.delivery-incomplete-container {
    padding: 16px 20px 16px 20px;
    margin-top: 10px;
}

.delivery-incomplete-value {
    color: var(--black85);
    margin-top: 9px;
    margin-bottom: 9px;
}

.delivery-radio-title {
    margin-top: 10px;
    margin-bottom: 5px;
}

.delivery-input-incomplete {
    /*margin-top: 11px;*/
    height: 40px;
}

.delivery-input-incomplete::placeholder {
    font-size: 14px;
}

.delivery-return {
    flex: 0;
    background: var(--errorActive);
    color: white;
    height: 30px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    margin-left: auto;
    border-width: 0;
}

.delivery-return:active {
    height: 30px;
}

.delivery-return:disabled {
    background: var(--black45);
    color: white;
    border-width: 0;
}

.delivery-done,
.delivery-done:focus,
.delivery-done:active,
.delivery-done:hover {
    background: #F5F5F5;
    /* Neutral / 5 */
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    text-shadow: none;
    border-radius: 14px;
    color: var(--black25)
}


.delivery-alias {
    color: var(--black85)
}

.delivery-link {
    width: 100%;
    padding: 0px 0px 0px 10px;
    height: 42px;
    display: flex;
    flex-direction: row;
    border-radius: 0px;
    align-items: center;
    justify-content: center;
    border: none;
}

.delivery-input {
    margin-bottom: 0px;
    height: 40px;
    border-radius: 7px;
    border: 1px solid #849FFF;
    box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
}

.delivery-input::placeholder {
    font-size: 14px;
}

.delivery-loc-entry {
    line-height: 30px;
}

.inputCode {
    flex: 1;
    margin-right: 10px;
}

.inputPass {
    flex: 2;
}

.inputInfo {
    margin-top: 10px;
}

.delivery-complete-image-container {
    margin-top: 22px;
    margin-bottom: 17px;
}

.delivery-complete-image {
    padding: 0px;
    width: 76px !important;
    height: 76px !important;
    margin-right: 10px;
    background: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 7px;
}

.delivery-camera {
    height: 28px;
    width: 28px;
}

.delivery-camera-txt {
    position: absolute;
    left: 5px;
    bottom: 5px
}

.delivery-preview-disabled {
    opacity: 0.15;
}

.delivery-button-camera-top {
    color: var(--primary) !important;
    margin-bottom: 6px;
}

.delivery-button-cntr {
    background: rgba(0, 0, 0, 0.06);
    padding: 2px;
    border-radius: 8.91px;
}

.delivery-button-camera {
    width: 100%;
    padding: 0px 16px!important; ;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: 6.93px;
    height: 31px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.5px;
    text-align: center;
    color: black;
}

.delivery-preview {
    width: 76px;
    height: 76px;
    box-sizing: border-box;
    border-radius: 7px;
}


.delivery-method-value {
    padding: 10px 20px;
}
