.container {
    height: 32px;
    display: flex;
    flex-direction: row;
    background: none;
}

.list {
    min-height: 300px;
}

.input {
    height: 32px;
    background: rgba(0, 0, 0, 0.85);
    border: 0.5px solid rgba(0, 0, 0, 0.45);
    border-radius: 7px;
    margin-top: 16px;
    color: white;
    font-size: 12px;
}

.input::placeholder{
    font-size: 12px;
}

.type {
    background: rgba(240, 240, 240, 0.1);
    border-radius: 4px;
    padding: 0px 10px;
    height: 20px;
    min-width: 80px;
}
