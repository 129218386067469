.container {
    width: 100%;
    background: white;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: auto;
    border: none;
}

.bottom {
    padding: 8px 10px;
    height: 40px !important;
}

.badge {
    width: 6px;
    height: 6px;
    background: #FFC61A;
    border-radius: 33px;
}

.link {
    width: 100%;
    padding: 0px 0px 0px 10px;
    height: 42px;
    display: flex;
    flex-direction: row;
    border-radius: 0px;
    align-items: center;
    justify-content: center;
    border: none;
}

.boxAlias {
    background: var(--black85);
    border-radius: 4px;
    height: 20px;
    left: 10px;
    top: 2px;
    padding: 0px 5px;
    color: white;
    margin-right: 5px;
}

.receiver {
    line-height: 17px;
}

.uploading {
    color: var(--yellow);
    margin-left: 5px;
}

.countCntr {
    flex: 1;
    height: 100%;
    width: 100%;
    color: var(--primary);
    text-align: right;
    border-left: 1px solid #f0f0f0;
    padding: 12px 10px;
}

.count {
    color: var(--primary);
    text-align: right;
    font-size: 13px;
}

.completeCountContainer {
    margin-top: 3px;
}

.content {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    margin-right: 0px;
    min-height: 81px;
}

.contentStatic {
    padding: 0px 10px 0px 0px;
    display: flex;
    align-items: center;
    margin-right: 0px;
    height: 97px;
}

.line2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.jibon {
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.backDisable {
    background: var(--black45);
    stroke: var(--black45);
}
