html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  background: var(--whitef3);
  /*font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans KR';*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
::-webkit-scrollbar { display: none; }
/*code {*/
/*  font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans KR', 'sans-serif';*/
/*}*/

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  src: url('./assets/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2'),url('./assets/fonts/SpoqaHanSansNeo-Regular.woff') format('woff'),url('./assets/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  src: url('./assets/fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2'), url('./assets/fonts/SpoqaHanSansNeo-Medium.woff') format('woff'), url('./assets/fonts/SpoqaHanSansNeo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  src: url('./assets/fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2'), url('./assets/fonts/SpoqaHanSansNeo-Bold.woff') format('woff'), url('./assets/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
