.container {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 14px;
    height: 102px;
    display: flex;
    justify-content: center;
    padding-left: 24px;
    flex-direction: column;
}

.label {
    margin-top: 8px;
}
