.intro-container {
    padding-top: 26vh;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 100px;
}

.intro-text {
    color: white;
    padding-top: 32px;
    white-space: pre-line;
}

.button {
    margin-top: 60px;
}
