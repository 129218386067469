.return-container {
    padding-top: var(--navbar-height);
    background: var(--black85);
    backdrop-filter: blur(4px);
    margin: 0px;
    width: 100% !important;
    /*z-index: 100100;*/
}

.return-header {
    background: var(--whitef3);
}

.return-image-container {
    width: 100vw;
    height: calc(100vh - (var(--navbar-height) + var(--tab-height)));
    display: flex;
    flex-direction: column;
}

.return-image {
    width: 100vw;
    max-height: 100vw;
    height: 80%;
    overflow: hidden;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
}
