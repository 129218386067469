.profile-container {
    display: flex;
    flex-direction: column;
    background: var(--whitef3);
}

.profile-inner-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 86px;
    flex: 1
}

.profile-name-container {
    background: var(--primary);
    height: 32vh;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 20px;
    margin-bottom: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-ride {
    margin-left: 5px;
}

.profile-bunny_type {
    border-radius: 4px;
    height: 20px;
    line-height: 19px;
    margin: 12px 4px;
    width: 60px;
    background: var(--whitef3);
    color: black
}

.profile-btn {
    display: flex;
    flex-direction: row;
    height: 44px;
    flex: 1;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(54.3656px);
    margin-top: 14px;
    box-shadow: none;
}

.profile-row-div {
    width: 10px
}

.profile-btn-text {
    color: black;
}

.profile-row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.profile-title {
    margin-top: 30px;
}

.profile-round-cntr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(54.3656px);
    border-radius: 14px;
}

.profile-divide-item {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
    height: 52px;
    border: none;
    border-radius: 14px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0.5px;
}

.profile-contact-btn{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
    height: 52px;
    border-radius: 14px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0.5px;
    border: 1px solid #0000000F
}

.profile-divider {
    height: 0.5px;
    background: var(--greyd9);
    flex: none;
    flex-grow: 0;
    width: 100%;
    z-index: 100;
}

.profile-label {
    color: black;
    text-align: left;
    line-height: 20px;
    padding-top: 5px;
}

.profile-link {
    color: var(--primary);
    line-height: 20px;
    padding-top: 2px;
}

.profile-info {
    padding-top: 10px;
    color: var(--black45)
}

.install-bottom {
    position: fixed;
    bottom: calc(var(--tab-height) - 15px);
    width: 10px;
    height: 10px;
    align-self: center;
    z-index: 10001;
}

.tooltip {
    z-index: 10001;
}

.profile-logout-cntr {
    margin-top: 58px;
    margin-bottom: 40px;
}

.profile-logout-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    align-items: center;
    height: 40px;
    border: 0.5px solid var(--black25);
    box-sizing: border-box;
    border-radius: 7px;
    margin-top: 10px;
    background: none;
    color: var(--black45);
}

.profile-logout-btn:hover,
.profile-logout-btn:focus,
.profile-logout-btn:active {
    border: 1px solid var(--black45);
    color: var(--black85);
    background: none;
}
