.install-btn {
    display: flex;
    flex-direction: row;
    height: 44px;
    width: 117px;
    border-radius: 14px;
    align-items: center;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

.install-logo {
    padding-right: 6px;
}

.install-text {
    color: var(--primary)
}

.install-text:hover {
    color: var(--btnBlue)
}

.install-tooltip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.install-tooltip-img {
    height: 100%;
    margin-right: 8px;
}

.install-tooltip {
    white-space: pre-line;
    color: white;
}
