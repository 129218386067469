.listContent {
    margin-bottom: var(--btn-primary-height);
    padding-bottom: 10px;
}

.container {
    padding-top: calc(var(--navbar-height) - 20px);
}

.row {
    margin-top: 42px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
