.order-item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    /*box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);*/
    margin-bottom: 10px;
    border-radius: 14px;
}

.order-unclaim-input {
    background: var(--black85);
    border: 0.5px solid var(--black45);
    height: 32px;
    border-radius: 7px;
    font-size: 12px;
    color: white;
    margin-top: 16px;
}
.order-unclaim-input::placeholder {
    font-size: 12px;
    color: var(--white45);
}

.order-item-top {
    padding: 5px 20px;
    height: 42px;
    align-items: center;
    width: 100%;
}

.order-item-middle {
    width: 100%;
    height: 108px;
    align-items: center;
    padding: 10px 20px;
}

.order-item-bottom {
    width: 100%;
    display: flex;
    height: 46px;
    align-items: center;
    justify-content: center;
}

.order-item-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}

.order-return {
    background: rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    color: var(--black45);
    min-width: 42px !important;
    width: 42px !important;
    height: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.order-item-box {
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    color: var(--whitef3);
    text-align: center;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.order-item-box {
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    color: var(--whitef3);
    text-align: center;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.order-item-box.BLUE {
    background: var(--primary);
    color: var(--whitef3);
}

.order-item-box.BLACK {
    background: var(--dark);
    color: var(--whitef3);
}

.order-item-box.WHITE {
    background: var(--whitef3);
    color: var(--primary);
}

.order-item-box.OFFROAD {
    background: var(--whitef3);
    color: var(--primary);
}

.order-item-box.RAINBOW {
    background: var(--pink);
    color: white;
}

.order-item-box.YELLOW {
    background: var(--yellow);
    color: white;
}

.order-check-btn {
    color: var(--primary) !important;
    background: transparent !important;
}

.order-check-btn:disabled {
    margin: 0px;
    padding: 0px;
    height: auto;
    color: var(--white45) !important;
    background: transparent !important;
}

.order-check {
    background: transparent;
    border: none;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: 100%;
    font-size: 13px;
    color: var(--white85)
}

.order-item-address2 {
    color: var(--black45);
    line-height: 22px;
    height: 22px;
}

.order-item-km {
    flex: 1;
    color: var(--primary)
}

.order-duetime {
    color: var(--primary);
    margin-left: auto;
}

.order-duration {
    text-align: right;
    margin-left: auto;
}

.fork-img {
    width: 24px;
    height: 24px;
}

.order-item-direction {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    margin-left: 27px;
}

.order-item-direction:active {
    height: 40px;
}

.order-item-map {
    width: 40px;
    height: 40px;
}

.order-item-cnt {
    text-align: center;
    flex: 1;
    box-shadow: -1px 0px 0px 0px #F0F0F0 inset;
    color: var(--black45);
}

.order-item-won {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-item-eyes {
    height: 22px;
    width: 48px;
    border-radius: 7px;
    padding: 3px 16px;
    margin-left: 5px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 7px;
}

.flat-map {
    width: 32px;
    height: 32px;
}

.order-item-btn {
    border: none;
    border-radius: 0px;
    font-weight: 700;
    font-size: 14px !important;
    color: var(--primary);
    box-shadow: -1px 0px 0px 0px #F0F0F0 inset !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.order-item-btn:hover {
    color: var(--hover)
}

.order-item-btn:disabled {
    flex: 1;
    border: none;
    font-weight: 700;
    border-radius: 0px;
    color: var(--black45);
    background: none;
    box-shadow: -1px 0px 0px 0px #F0F0F0 inset;
}

.order-item-scan {
    border-radius: 0px;
    color: var(--errorActive);
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 1;
    box-shadow: -1px 0px 0px 0px #F0F0F0 inset;
}

.order-item-scan:disabled {
    background-color: transparent;
    border-width: 0px;
    color: var(--black45);
    box-shadow: -1px 0px 0px 0px #F0F0F0 inset;
}

.order-item-scan:hover{
    color: var(--error);
}

.order-item-scan:active{
    color: var(--error);
}

.order-item-scan:focus{
    color: var(--error);
}

.order-item-won {
    text-align: center;
    flex: 1;
    color: var(--black85);
}

.order-item-won:hover {
    text-align: center;
    flex: 1;
    color: var(--black45);
}

.order-item-divider {
    width: 1px;
}

.order-container {
    background: var(--whitef9);
    padding-top: 30px;
    padding-bottom: var(--tab-height);
}

.order-black-container {
    background: var(--whitef9);
    padding-top: 10px;
    padding-bottom: var(--tab-height);
}

.order-row {
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 28px;
}

.order-btn-thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-left: 3px;
}
