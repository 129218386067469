.helmet-container {
    background: var(--black85);
    backdrop-filter: blur(4px);
    margin: 0px;
    padding-top: 0px;
    width: 100%;
}

.helmet-header {
    backdrop-filter: blur(30px);
    background: var(--black85);
    border-bottom: 0px;
    color: white
}

.helmet-title {
    color: white;
}

.helmet-img-container {
    height: 100%;
    width: 100vw;
    flex-direction: column;
    display: flex;
}

.helmet-btn-container {
    z-index: 101;
    display: flex;
    align-items: flex-end;
    height: 24vh;
    bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.helmet-btn-add {
    z-index: 100;
    margin-bottom: 64px;
}

.helmet-svg-round {
    box-sizing: border-box;
    border-radius: 20px;
    border: 2.5px solid var(--primary);
    margin-top: 20px;
    margin-bottom: 11px;
    height: 20px;
    width: 20px;
    border-radius: 20px;

}

