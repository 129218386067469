.nearbyImg {
    width: 18px;
    height: 18px;
}

.compassImg {
    width: 16px;
    height: 16px;
    margin-top: 4px;
}

.refreshImg {
    width: 20px;
    height: 20px;
}

.scanImg {
    width: 28px;
    height: 23px;
}

.myLocationBtn {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 52px;
    border-width: 0px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 6px 30px rgba(0, 0, 0, 0.04), 0px 8px 10px rgba(0, 0, 0, 0.08);
    margin-left: auto;
    right: 20px;
}

.deliveryTop {
    bottom: 346px !important;
}

.containerTop {
    bottom: 320px !important;
}

.warningOneTop {
    bottom: 180px !important;
}

.warningTop {
    bottom: 230px !important;
}

.leftFloating {
    top: 10px;
    left: 10px
}

.rightFloating {
    top: 10px;
    right: 10px
}

.floatingBtnCntr {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.floatingBtn,
.floatingBtn:active,
.floatingBtn:focus
{
    height: 48px;
    width: 48px;
    border-radius: 7px;
    padding: 3px 10px 3px 10px;
    font-weight: bold;
    font-size: 13px;
    color: var(--primary);
    margin-bottom: 5px;
    background: white;
    border-width: 0px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 6px 30px rgba(0, 0, 0, 0.04), 0px 8px 10px rgba(0, 0, 0, 0.08);
}

.zoomBtn {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #7d7d7d;
    height: 39px;
    width: 47px;
    border: none;
}

.zoomDivider {
    height: 1px;
    width: 100%;
    background: var(--greyd9);
}

.zoomTop {
    border-radius: 7px 7px 0px 0px;
}

.zoomBtm {
    border-radius: 0px 0px 7px 7px;
}

.floatingContainer {
    position: absolute;
    z-index: 1000;
    bottom: var(--tab-height);
    left: 0px;
    right: 0px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.floatingMapContainer {
    bottom: 0;
}

.deliveryItem {
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px
}

.filterContainer {
    position: fixed;
    top: 10px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
}

.filterBtn {
    width: 39px;
    height: 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 0px;
    box-shadow: inset -1px 0px 0px var(--greyd9), inset 0px 1px 0px var(--greyd9), inset 0px -1px 0px var(--greyd9);
}

.filterBtn:active {
    height: 24px;
}

.filterBtnL {
    border-radius: 24px 0px 0px 24px;
}

.filterBtnM {
    border-radius: 0px;
}

.filterBtnR {
    border-radius: 0px 24px 24px 0px;
}

.filterBtnR:hover {
    border-radius: 0px 24px 24px 0px;
}

.mapContainer {
    padding-top: var(--navbar-height);
    width: 100vw;
    height: 100vh;
}
