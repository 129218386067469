.container {
    background: #F3F3F3;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.08);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    width: 100%;
}

.itemContainer {
    width: 100%;
    background: white;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: auto;
    border: none;
}

.itemInfo {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 17px;
    margin-top: 7px;
}
