.rowTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
}

.aliasContainer {
    height: 20px;
    padding: 0px 5px;
    background: var(--black85);
    border: 1.5px solid var(--whitef3);
    box-sizing: border-box;
    border-radius: 4px;
    color: var(--whitef3);
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
