.empty {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeCntr {
  z-index: 100;
  position: absolute;
  width: 100vw;
  height: 100px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 24px;
  padding-right: 24px;
  justify-content: flex-end;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.close {
  heigth: 20px;
  width: 20px;
}

.modal {
  position: absolute; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw; /* Full width */
  flex-grow: 1;
  overflow: auto; /* Enable scroll if needed */
  background: rgba(0, 0, 0, 0.65);
}

.imgCntr {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.img {
  width: 100%;
  height: 272px;
  object-fit: contain;
  margin-bottom: 10px;
}

.fullImage {
  width: 100vw;
  object-fit: contain;
}
