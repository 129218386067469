.container {
    padding-top: 70px;
}

.tab {
    margin-top: 10px;
    margin-bottom: 20px;
}

.list {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(54.3656px);
    border-radius: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.divider {
    width: 100%;
    height: 0.5px;
    background: #D9D9D9;
}
