.account-container {
    padding-top: calc(var(--navbar-height) + 30px);
    margin-bottom: 100px;
}

.account-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.account-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
}

.account-name {
    margin-top: 5px;
    margin-bottom: 0px;
}

.form-item {
    margin-bottom: 0px;
}

.info-container {
    align-items: flex-start;
    padding: 20px 10px 20px 20px;
    background: var(--grey8c);
    border: 1px solid var(--grey8c);
    box-sizing: border-box;
    border-radius: 14px;
    margin-bottom: 20px;
    margin-top: auto;
}

.account-info {
    white-space: pre-line;
    color: white;
}

.account-divide-item {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    width: 100%;
    height: 52px;
    border: none;
    border-radius: 14px;
    justify-content: left;
    align-items: center;
    margin-top: 0.5px;
}

.account-upload-img {
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border-radius: 7px;
    margin-right: 10px;
}

.account-upload-btn {
    height: 60px !important;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 14px !important;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 30px;
}

.account-title-bank {
    margin-top: 30px;
    margin-bottom: 5px;
}

.account-title {
    margin-top: 0px;
    margin-bottom: 5px;
}

.account-label {
    text-align: left;
    line-height: 20px;
    padding-top: 5px;
    color: black;
}

.account-label:hover {
    color: var(--black45);
}

.account-svg {
    margin-right: 10px;
}
