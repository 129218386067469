.edu-info {
    margin-top: 40px;
    color: var(--primary);
    text-align: left;
    white-space: pre-line;
}

.edu-link-container {
    background: white;
    height: 60px;
    border-radius: 14px;
    margin-top: 30px;
    padding: 10px 12px 20px;
    border: 1px solid var(--greyd9);
}

.edu-link-container:hover {
    border: 1px solid var(--greyd9);
    background: white;
}

.edu-link {
    padding-bottom: 0px;
    padding-top: 10px;
    text-decoration: underline;
    color: var(--primary);
    width: 100px;
    text-align: left;
}

.edu-link:hover {
    color: var(--primary);
}
