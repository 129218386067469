.tab-container {
    position: fixed;
    display: flex;
    flex-direction: row;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--whitef9);
    backdrop-filter: blur(27.1828px);
    box-shadow: 0px 0.5px 0px 0px #00000033 inset;
}

.tab-link {
    flex: 1;
    position: relative;
}

.tab-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: var(--tab-height);
    padding-top: 7px;
}

.tab-active {
    padding-top: 5px;
    text-align: center;
    color: var(--primary)
}

.tab-icon {
    width: 30px;
    height: 28px;
}

.tab-text {
    padding-top: 5px;
    text-align: center;
    color: #00000073;
}

.tab-count {
    padding: 0px 6px;
    position: absolute;
    right: 8px;
    top: -1px;
    background: var(--badge);
    border-radius: 10px;
    z-index: 1000;
    color: white;
    height: 20px;
    min-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-badge {
    position: absolute;
    right: 32px;
    top: 3px;
    width: 6px;
    height: 6px;
    background: var(--badge);
    box-shadow: 0px 0px 0px #FFFFFF;
    border-radius: 32px;
}
