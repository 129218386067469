.left-container {
    display: flex;
    flex-direction: column;
    background-color: var(--whitef3);
}

.left-inner-container {
    display: flex;
    flex-direction: column;
    padding-top: 106px;
    flex: 1;
}

.title-inner {
    color: rgba(0, 0, 0, 0.85);
}

.subtitle-inner {
    text-align: left;
    white-space: pre-line;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 10px;
}
