.term-all {
    margin-top: 30px;
    width: 100%;
    background-color: white;
}

.term-all-txt {
    font-weight: 700;
}

.term-form {
    margin-bottom: 107px;
}

.term-check {
    width: 100%;
    position: relative;
    margin-top: 10px;
    left: -10px;
    display: flex;
    flex-direction: row;
    background-color: var(--whitef3);
}

.term-check-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.term-check-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    position: absolute;
    right: 20px;
    width: 60px;
    top: 15px;
    padding: 0px;
    z-index: 100;
    color: var(--primary);
    justify-content: space-between;
}
