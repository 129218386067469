.header-container {
    display: flex;
    align-items: center;
    height: var(--navbar-height);
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    background: var(--black02);
    backdrop-filter: blur(30px);
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    border-bottom: 0.5px solid var(--greyc6);
}

.left-node {
    flex: 20vw;
    color: var(--hover);
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: left;
}

.left-node:hover {
    color: var(--btnBlue);
}

.right-node {
    flex: 20vw;
    color: var(--hover);
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: right;
}

.right-node:hover {
    color: var(--btnBlue);
}

.title-node {
    width: 100%;
    color: black;
}
