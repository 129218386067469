.container {
    padding-top: calc(var(--navbar-height) + 10px);
}

.space {
    height: 20px;
}

.shippingTitle {
    line-height: 28px;
    margin-top: 20px;
}

.whiteRoundCntr {
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    min-height: 60px;
    align-items: center;
    padding: 10px;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.icon {
    width: 43px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    box-shadow: none;
}

.img {
    width: 28px;
    height: 28px;
}

.divider {
    box-shadow: inset -1px 0px 0px #F0F0F0;
}

.iconMobile {
    width: 58px;
}
