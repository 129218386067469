.notify-para {
    margin-top: 20px;
}

.notify-title {
    margin-bottom: 10px;
    color: var(--black85)
}

.notify-text {
    white-space: pre-line;
    color: var(--black45)
}

.notify-block-route {
    padding-left: 50px;
    color: var(--black85);
    padding-bottom: 20px;
}

.notify-block {
    white-space: pre-line;
    color: var(--black85)
}

.notify-block-text {
    white-space: pre-line;
    color: var(--black45);
    padding-top: 30px;
}
