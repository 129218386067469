.login-cntr {
    padding-top: 106px;
}

.form-phone {
    margin-bottom: 0px;
}

.form-code {
    margin-top: 30px;
    margin-bottom: 20px;
}

.form-get-code {
    margin-bottom: 0px;
}

.form-verify-btn {
    margin-bottom: 0px;
}

.input-phone {
    margin-top: 60px;
}

.input-code {
    /*border-radius: 14px;*/
    height: 60px;
}

.btn-get-code {
    margin-top: 10px;
}

.btn-verify {
    margin-top: 20px;
    background-color: var(--primary);
}

.timer {
    margin-top: 0;
}
