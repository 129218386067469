.round-container {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(54.3656px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 14px;
}

.notify-container {
    padding-top: var(--navbar-height);
}

.notify-divide-item {
    height: 52px;
    width: 100%;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
}

.notify-label {
    margin-bottom: 10px;
    margin-top: 30px;
}
