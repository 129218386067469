.container {
    background: white;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.deliveryRow {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    min-height: 60px;
    align-items: center;
    padding: 10px;
}

.badge {
    width: 6px;
    height: 6px;
    background: #FFC61A;
    border-radius: 33px;
    margin-left: 5px;
}

.uploading {
    color: var(--yellow);
    margin-left: 5px;
}

.deliveryCol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.tag {
    margin-bottom: 3px;
}

.icon {
    width: 43px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    box-shadow: none;
}

.value {
    color: var(--black85);
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    word-wrap: break-word;
}

.return {
    flex: 0;
    background: var(--errorActive);
    color: white;
    height: 30px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    margin-left: auto;
    border-width: 0;
}

.return:active {
    height: 30px;
}

.return:disabled {
    background: var(--black45);
    color: white;
    border-width: 0;
}

.done,
.done:focus,
.done:active,
.done:hover {
    background: var(--black45);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    text-shadow: none;
    border-radius: 14px;
    color: white;
}

.img {
    width: 28px;
    height: 28px;
}


