.cntr {
    padding: 20px !important;
    width: 100vw;
    margin-top: var(--navbar-height);
    margin-bottom: var(--tab-height);
}

.webView {
    height: calc(100vh - (var(--tab-height) - var(--navbar-height)));
    width: 100vw;
}
