.item {
    width: 100%;
    height: 110px;
    margin-bottom: 10px;
}

.top {
    padding: 5px 20px;
}

.bottom {
    padding: 14px 20px;
    height: 100%;
}
