.deliveryItem {
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px
}

.container {
    background: #F3F3F3;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.08);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    width: 100%;
    height: 252px;
}

.option {
    height: 10px;
    color: #00000085;
    min-height: 30px
}

.optionFont {
    font-size: 12px;
}

.sortContainer {
    margin-left: auto;
    width: 82px;
    margin-right: 20px;
    margin-bottom: 8px;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
}
