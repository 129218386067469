.spinner-container {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
