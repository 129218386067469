.camera-container {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
}

.camera-inner-container {
    width: 100vw;
    height: 100%;
    overflow: hidden;
}

.camera-btn-container {
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 24%;
    padding-left: 20px;
    padding-right: 20px;
}

.camera-res-btn-container {
    z-index: 101;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 24%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
}

.camera-dim {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: var(--black80);
    backdrop-filter: blur(4px);
}

.camera-img {
    position: absolute;
    left: 20px;
    top: 73px;
    width: calc(100% - 40px);
    height: calc(80% - 101px);
    z-index: 100;
    object-fit: cover;
    pointer-events: none;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
    border-radius: 14px;
}

.camera-img-back {
    left: 0%;
    top: 45px;
    z-index: 10;
    object-fit: contain;
    width: 100vw;
    pointer-events: none;
}
